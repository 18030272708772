import dayjs from "dayjs";
import diff from "dayjs";
import NavigationButton from "components/ui/NavigationButton";
import PrimaryButton from "components/ui/PrimaryButton";

type Props = {
  handlePrevMonth: any
  handelNextMonth: any
  handleReset: any
  // targetMonth: dayjs.Dayjs
  // targetYear: dayjs.Dayjs
  // today: dayjs.Dayjs
  prevLimit: dayjs.Dayjs
  nextLimit: dayjs.Dayjs
  targetDate: dayjs.Dayjs
}

// const today = dayjs();


export default function CalendarHeader(props: Props) {

  const targetDate = props.targetDate;
  // const today = props.today;
  const prevLimit = props.prevLimit;
  const nextLimit = props.nextLimit;

  return (
    <div className="calender_head">

      <div className="head_l">
        <p className="month">
          {targetDate.format("M")}
        </p>
        <p className="year_month">
          {targetDate.format("YYYY")}<br />
          {targetDate.format("MMMM")}
        </p>
      </div>

      <div className="head_r">

        <NavigationButton onClick={props.handlePrevMonth} variant={"left" + (targetDate.diff(prevLimit, 'month') <= 0 ? " disabled" : "")}/>

        <PrimaryButton mode="button" path="" variant="no_arrow u__sp_none" onClick={props.handleReset}>Today</PrimaryButton>

        <NavigationButton onClick={props.handelNextMonth} variant={targetDate.diff(nextLimit, 'month') >= 0 ? " disabled" : ""} />

      </div>

      <PrimaryButton mode="button" path="" variant="no_arrow u__sp_only" onClick={props.handleReset}>Today</PrimaryButton>

    </div>
  );
};