// React
import { useEffect } from 'react';
// 外部ライブラリ
import { Helmet } from "react-helmet-async"
import { motion } from "framer-motion"
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join, nl2br } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
// コンポーネント
import PrimaryHeading from 'components/headings/PrimaryHeading'
import PrimaryButton from "components/ui/PrimaryButton"
// 定数
import { SITE_TITLE, SITE_URL_BASE } from 'config/Constants';
// 型定義
// 画像
// その他
type Props = {
}
export default function Submitted(props: Props) {

  // アニメーション用
  useEffect(() => {
    const observer = commonAnimationObserver();
    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Contact｜${SITE_TITLE}`}</title>
        <meta name="description" content={`お問い合わせ完了｜${SITE_TITLE}`} />
        <meta property="og:url" content={SITE_URL_BASE + "/contact/submitted"} />
        <meta property="og:title" content={`Contact｜${SITE_TITLE}`} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p__contact_submitted" className="l__main_content">
          <PrimaryHeading>Contact</PrimaryHeading>
          <p className="submitted_description c__common_text_primary">
            この度はお問い合わせいただきありがとうございます。<br />
            担当者よりお返事させていただきますので、しばらくお待ちください。<br />
            <br />
            なお、4～5日経過してもお返事がない場合は、<br />
            入力いただいたメールアドレスに誤りがある可能性がございます。<br />
            その際は、お手数ですが再度お問い合わせいただきますよう、お願い申し上げます。
          </p>
          <PrimaryButton mode="link" path="/" variant="back_to_top">Top</PrimaryButton >

        </main>
      </motion.div>

    </>
  );
}