import { useLocation } from 'react-router-dom';

const useCurrentPage = (): string => {

  const location = useLocation();
  const path = location.pathname;

  if (path === "/") {
    return "home"
  } else if (path === "/news") {
    return "news"
  } else if (path.includes("news")) {
    return "news_show"
  } else if (path.includes("profile")) {
    return "profile"
  } else if (path.includes("schedule")) {
    return "schedule"
  } else if (path.includes("contact")) {
    return "contact"
  } else if (path.includes("terms")) {
    return "terms"
  } else {
    return "notfound"
  }
}
export default useCurrentPage;