import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "./qs";

export function setDvh(): void {
  setTimeout(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--dvh', `${vh}px`)
  }, 500);
}
export function setSvh(): void {
  setTimeout(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--svh', `${vh}px`);
  }, 500);
}

export function scrollTo(offset: number): void {
  window.scrollTo({
    top: offset,
    behavior: "smooth"
  });
}
export function scrollToSelector(sel: string): void {
  const target = qs(sel)
  if (target) scrollTo(target.offsetTop)
}



export function getHeaderHeight(): number {
  const pc_header_selector = "#header .header_l";
  const pcHeaderHeight = qs(pc_header_selector)!.offsetHeight;
  return pcHeaderHeight + 40;
}

export function loading(selector: string = ""): void {
  let targets;
  if (selector) {
    targets = qsAll(selector);
  } else {
    targets = qsAll(".js__load_required");
  }
  for (const elm of targets) {
    removeClass(elm, "loaded");
    removeClass(elm, "failed");
  }
}
export function loaded(selector: string = ""): void {
  setTimeout(() => {
    let targets;
    if (selector) {
      targets = qsAll(selector);
    } else {
      targets = qsAll(".js__load_required");
    }
    for (const elm of targets) {
      addClass(elm, "loaded");
    }
  }, 500);
}


// スクロール禁止
export function preventScroll() {
  document.addEventListener("wheel", preventDef, { passive: false });
  document.addEventListener("touchmove", preventDef, { passive: false });
}

// スクロール禁止解除
export function arrowScroll() {
  document.removeEventListener("wheel", preventDef);
  document.removeEventListener("touchmove", preventDef);
}

// preventDefault
function preventDef(e: any) {
  e.preventDefault();
}