import { NewsCategory } from 'components/models/newsCategory/Types';
import { ScheduleCategory } from 'components/models/scheduleCategory/Types';
import { atom } from 'recoil';
const newsCategoryAtom = atom({
  key: "newsCategoryAtom",
  default: [] as NewsCategory[],
});
const scheduleCategoryAtom = atom({
  key: "scheduleCategoryAtom",
  default: [] as ScheduleCategory[],
});
export {
  newsCategoryAtom,
  scheduleCategoryAtom
};