// React
import { useEffect, useState } from 'react'
// 外部ライブラリ
import axios from 'axios'
import { Link, useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { motion } from "framer-motion"
import { Helmet } from "react-helmet-async"
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join, nl2br, unEscapeHtmlSpecialCharacters, html2excerpt, removeLineBreaks } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
import { getParam } from 'modules/url'
import { addStalkerHoverEvent, removeStalkerHoverEvent } from "modules/mouseStalker"
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { historyCategoryColor, getCategoryNumber, getNewsCategoryColorClass } from 'modules/projectModules'
// コンポーネント
import PrimaryHeading from 'components/headings/PrimaryHeading'
import PrimaryButton from 'components/ui/PrimaryButton'
import Arrow from 'components/ui/commonSvg/Arrow'
// 型定義
import { Article } from "components/models/news/Types";
// 定数
import { SITE_TITLE, SITE_URL_BASE, API_BASE_URL } from 'config/Constants';
// 画像
import x_icon from 'assets/img/common/web_services_icon/x_logo_bk.svg';
import useNewsCategoryColor from 'hooks/useNewsCategoryColor'
// その他

type Props = {
}
const initialArticle: Article = {
  title: "",
  publishedDate: "",
  category: "",
  categoryColorOrder: null,
  content: "",
  excerpt: "",
};

export default function Show(props: Props) {

  const { id } = useParams(); //ルーティングで使っているパラメータ
  const [article, setArticle] = useState<Article>(initialArticle);
  const [prevArticleId, setPrevArticleId] = useState("");
  const [nextArticleId, setNextArticleId] = useState("");

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  const navigate = useNavigate();

  // カテゴリカラー
  const newsCategoryColor = useNewsCategoryColor().newsCategory;

  // 記事詳細を取得
  useEffect(() => {

    const previewQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `/article_detail/?id=${id}${previewQuery}`;
    const uri = API_BASE_URL + query

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {

        // 公開日を初期化、無効な日付だった場合は空にする
        let date = dayjs(res.data.date).format('YYYY.MM.DD');
        if (date === "Invalid Date") {
          date = "";
        }

        // 記事情報のセット
        const artice: Article = {
          title: String(res.data.title),
          publishedDate: date,
          category: res.data.cat_names[0],
          categoryColorOrder: getCategoryNumber(newsCategoryColor, res.data.cat_names[0]),
          content: res.data.content,
          excerpt: html2excerpt(res.data.content),
        };

        // 記事情報のセット
        setArticle(artice);

        if (res.data.prev) setPrevArticleId(res.data.prev.id);
        if (res.data.next) setNextArticleId(res.data.next.id);

      })
      .catch(function (e) {
        navigate('/notfound')
      })
  }, []);

  /*=======================================
    記事内twitter埋め込み動かない対策
  =======================================*/
  const script = document.createElement('script');
  script.src = "https://platform.twitter.com/widgets.js";
  useEffect(() => {
    // twitterまわりの処理
    const twitter_widget_wrapper = qs("#twitter_widget_wrapper");
    twitter_widget_wrapper?.appendChild(script);
    return () => {
      // twitterまわりのクリーンアップ
      twitter_widget_wrapper?.removeChild(script);
    };
  }, [article]);

  useEffect(() => {
    setTimeout(() => {
      loaded(".article_wrapper");
    }, 300);
  }, [article]);

  /*=======================================
    記事内リンクに対しての設定
  =======================================*/
  // aタグに対してホバーの反応と外部リンクで開く設定を追加
  useEffect(() => {
    const selector: string = ".c__article_body a"
    setTimeout(() => {
      addStalkerHoverEvent(selector);
      const anchors = qsAll(selector);
      for (const anchor of anchors) {
        anchor.setAttribute("target", "_blank");
        anchor.setAttribute("rel", "noopener noreferrer");
      }
    }, 500);
    return () => {
      removeStalkerHoverEvent(selector);
    }
  }, []);

  // iframeと重なったらマウスストーカーを非表示にする
  useEffect(() => {
    let stalker: HTMLElement | null = null;
    let iframes: Array<HTMLElement> = [];
    setTimeout(() => {
      stalker = qs("#mouse_stalker");
      iframes = qsAll("iframe");
      for (const elm of iframes) {
        elm.addEventListener('mouseover', function () {
          addClass(stalker, "disabled");
        });
        elm.addEventListener('mouseout', function () {
          removeClass(stalker, "disabled");
        });
      }
    }, 1000);
    return () => {
      for (const elm of iframes) {
        elm.removeEventListener('mouseover', function () {
          addClass(stalker, "disabled");
        });
        elm.removeEventListener('mouseout', function () {
          removeClass(stalker, "disabled");
        });
      }
    }
  }, []);


  function prevPager() {
    const cls = prevArticleId === "" ? " disabled" : "";
    return (
      <Link to={`/news/${prevArticleId}`} className={"pager_link_prev" + cls} title="前の記事" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
        <Arrow />
      </Link>
    )
  }
  function nextPager() {
    const cls = nextArticleId === "" ? " disabled" : "";
    return (
      <Link to={`/news/${nextArticleId}`} className={"pager_link_next" + cls} title="次の記事" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
        <Arrow />
      </Link>
    )
  }

  function twitterShareHref() {
    const url = encodeURIComponent(window.location.href);
    const truncatedTitle = article.title.length > 34 ? (article.title.substring(0, 34) + "...") : article.title;
    const title = encodeURIComponent(truncatedTitle + "｜紡木吏佐オフィシャルサイト");
    const hashtag = encodeURIComponent("紡木吏佐");
    return (
      `https://twitter.com/share?url=${url}&text=${title}&hashtags=${hashtag}`
    )
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content={`${article.title} News - ${SITE_TITLE}`} />
        <meta property="og:url" content={SITE_URL_BASE + "/news/" + id} />
        <title>{article.title} News - {SITE_TITLE}</title>

        {/* 一気にやろうとしてfragmentで囲むとhelmetが動かなくなるため一つずつ処理する */}
        {article.excerpt &&
          <meta property="og:description" content={article.excerpt} />
        }
        {article.excerpt &&
          <meta name="description" content={article.excerpt} />
        }
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p__news_show" className="l__main_content">

          <PrimaryHeading>News</PrimaryHeading>

          <article className="article_wrapper js__load_required">

            <div className="article_head_info">
              <div className="info_above">
                <div className="date">
                  {article.publishedDate}
                </div>
                <div className={"category" + getNewsCategoryColorClass(article.categoryColorOrder)}>
                  {article.category}
                </div>
              </div>
              <div className="info_bottom">
                <h1 className="title">{article.title}</h1>
                <a className="share"
                  href={twitterShareHref()} target="_blank" rel="noopener noreferrer" title="" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                  <img src={x_icon} className="x_icon" alt="x" />
                  <p className="share_text">Share</p>
                  <Arrow />
                </a>
              </div>
            </div>

            <div className="c__article_body main_content" dangerouslySetInnerHTML={{ __html: article.content }} />

            <div className="c__article_prev_next">
              {prevPager()}

              <PrimaryButton mode='link' path="/news" title="一覧に戻る" variant="no_arrow">View all</PrimaryButton>

              {nextPager()}

            </div>

          </article>

          <div id="twitter_widget_wrapper"></div>

        </main>
      </motion.div>
    </>
  );
}