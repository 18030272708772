// React
import { useEffect, useState } from 'react'
// 外部ライブラリ
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import axios from 'axios';
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { nl2br } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
import { getNewsCategoryColorClass, historyCategoryColor, nl2li } from 'modules/projectModules'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
// コンポーネント
import PrimaryHeading from 'components/headings/PrimaryHeading'
import History from './History'
import NavigationButton from 'components/ui/NavigationButton';
// 型定義
// 定数
import { SITE_TITLE, SITE_URL_BASE, API_BASE_URL } from 'config/Constants';
// 画像
import playImg from 'assets/img/profile/play.svg'
import pauseImg from 'assets/img/profile/pause.svg'

// その他
import sample1 from "assets/audio/sample_voice/1.mp3"
import sample2 from "assets/audio/sample_voice/2.mp3"
import sample3 from "assets/audio/sample_voice/3.mp3"
import sample4 from "assets/audio/sample_voice/4.mp3"
import sample5 from "assets/audio/sample_voice/5.mp3"
import sample6 from "assets/audio/sample_voice/6.mp3"
import sample7 from "assets/audio/sample_voice/7.mp3"
import sample8 from "assets/audio/sample_voice/8.mp3"




type Props = {
}


export default function VoiceSample(props: Props) {


  /*=======================================
    背景操作用
  =======================================*/

  // アニメーション用
  useEffect(() => {
    const audios = qsAll("audio")

    for (const audio of audios) {
      audio.addEventListener("loadedmetadata", setDuration)
      audio.addEventListener("ended", stop)
    }
    return () => {
      for (const audio of audios) {
        audio.removeEventListener("loadedmetadata", setDuration)
        audio.removeEventListener("ended", stop)
      }
    };
  }, []);


  const [playerInterval, setPlayerInterval] = useState<null | NodeJS.Timer>(null);


  function togglePlay(e: any) {

    const targetAudio = qs("audio#" + e.target.dataset.audioId)
    if (!targetAudio) return;

    if (targetAudio.paused) {
      play(targetAudio)
    } else {
      pause(targetAudio)
    }
  }

  function play(audio: any) {

    // 他に再生中のものがあれば先に停止する
    const playingElement = qs("audio.active")
    if (playingElement && !playingElement.paused) {
      pause(playingElement)
    }

    audio.play()
    addClass(audio, "active")

    const playButton = qs("img.play_button[data-audio-id='" + audio.id + "']")
    playButton.src = pauseImg
    const progressInterval = setInterval(() => { updateCurrentTime(audio) }, 1000)
    setPlayerInterval(progressInterval)
  }

  function pause(audio: any) {
    audio.pause()
    removeClass("audio.active", "active")
    const playButton = qs("img.play_button[data-audio-id='" + audio.id + "']")
    playButton.src = playImg
    if (playerInterval) clearInterval(playerInterval)
    setPlayerInterval(null)
  }

  function stop(e: any) {
    const audio = e.target
    audio.pause()
    removeClass("audio.active", "active")
    const playButton = qs("img.play_button[data-audio-id='" + audio.id + "']")
    playButton.src = playImg

    const currentTimeNum = 0
    const progress = 0
    audio.currentTime = 0
    const currentProgress = qs(".current_progress[data-audio-id='" + audio.id + "']")
    currentProgress.style.width = `${progress * 100}%`
    const currentTime = qs(".current_time[data-audio-id='" + audio.id + "']")
    currentTime.innerHTML = formatTime(currentTimeNum)
    if (playerInterval) clearInterval(playerInterval)
    setPlayerInterval(null)
  }

  function updateCurrentTime(audio: any) {
    const currentTimeNum = audio.currentTime
    const progress = currentTimeNum / audio.duration

    const currentProgress = qs(".current_progress[data-audio-id='" + audio.id + "']")
    currentProgress.style.width = `${progress * 100}%`

    const currentTime = qs(".current_time[data-audio-id='" + audio.id + "']")
    currentTime.innerHTML = formatTime(currentTimeNum)
  }

  function formatTime(time: number) {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    const minutesText = minutes
    const secondsText = seconds < 10 ? `0${seconds}` : seconds
    return `${minutesText}:${secondsText}`
  }

  // クリックされた時に動くやつ
  function setProgress(e: any) {
    const totalProgress = e.target
    const width = totalProgress.clientWidth
    const clickX = e.nativeEvent.offsetX // 通常はe.offsetXで取れるが、reactのマウスイベントの場合はこの書き方をする
    const audio = qs("audio#" + totalProgress.dataset.audioId)
    const selectedTime = (clickX / width) * audio.duration

    audio.currentTime = selectedTime
    updateCurrentTime(audio)
  }

  function setDuration(e: any) {
    const audio = e.target
    const duration = audio.duration
    const totalTime = qs(".total_time[data-audio-id='" + audio.id + "']")
    totalTime.innerHTML = formatTime(duration)
  }

  function generateAudioPlayer() {
    const audios = [
      {
        title: "サンプル1",
        src: sample1
      },
      {
        title: "サンプル2",
        src: sample2
      },
      {
        title: "サンプル3",
        src: sample3
      },
      {
        title: "サンプル4",
        src: sample4
      },
      {
        title: "サンプル5",
        src: sample5
      },
      {
        title: "サンプル6",
        src: sample6
      },
      {
        title: "サンプル7",
        src: sample7
      },
      {
        title: "サンプル8",
        src: sample8
      },
    ]

    return (
      <>
        {audios.map((audio: any, index: number) => {
          const audioId = "audio" + index
          return (
            <li className={"sample_item" + (index >= 3 ? " sp_fold" : "")} key={index}>
              <p className="title">{audio.title}</p>
              <div className="player">
                <img className="play_button" id={"play_button" + index} src={playImg} alt="" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} data-audio-id={audioId} onClick={(e: any) => togglePlay(e)} />
                <audio src={audio.src} preload="metadata" id={audioId}></audio>
                <p className="time" data-audio-id={audioId}>
                  <span className="current_time" data-audio-id={audioId}>0:00</span> / <span className="total_time" data-audio-id={audioId}>0:00</span>
                </p>
                <div className="progress_bar">
                  <div className="current_progress" data-audio-id={audioId}></div>
                  <div className="total_progress" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} data-audio-id={audioId} onClick={(e: any) => setProgress(e)}></div>
                </div>
              </div>
            </li>
          )
        })}
      </>
    )
  }

  function handleFoldClick() {
    const sampleItemList = qs(".sample_item_list")
    toggleClass(sampleItemList, "folded")
    const navigationButton = qs(".fold_button")
    toggleClass(navigationButton, "down")
    toggleClass(navigationButton, "up")
  }

  return (
    <>
      <div className="voice_sample_wrapper">
        <div className="voice_sample_head">
          Voice Sample
        </div>
        <ul className="sample_item_list folded">
          {generateAudioPlayer()}
          <NavigationButton variant='circle down small fold_button' onClick={handleFoldClick} />
        </ul>
      </div>
    </>
  );
}