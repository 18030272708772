// React
import { useEffect, } from 'react';
// 外部ライブラリ
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
// コンポーネント
import Calender from "./Calender";
import PrimaryHeading from 'components/headings/PrimaryHeading'
// 型定義
// 定数
import { SITE_TITLE, SITE_URL_BASE } from 'config/Constants';
// 画像
// その他

type Props = {
}

export default function Index(props: Props) {

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  /*=======================================
    背景操作用
  =======================================*/

  // アニメーション用
  useEffect(() => {
    const observer = commonAnimationObserver();
    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Schedule｜${SITE_TITLE}`}</title>
        <meta property="og:url" content={SITE_URL_BASE + "/schedule"} />
        <meta property="og:title" content={`Schedule｜${SITE_TITLE}`} />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p__schedule" className="l__main_content">

          <PrimaryHeading>Schedule</PrimaryHeading>

          <Calender/>

        </main>
      </motion.div>
    </>
  );
}