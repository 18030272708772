// React
import { useEffect } from 'react';
// 外部ライブラリ
import { useLocation } from 'react-router-dom';
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join, nl2br } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { generateISObserver } from 'modules/ISObserver'
// コンポーネント
// 型定義
// 定数
// 画像
// import fv_bg_pc from 'assets/img/home/fv_bg_pc2.png'
import fv_bg_pc from 'assets/img/home/fv_bg_pc5.jpg'
// import fv_risa4 from 'assets/img/home/fv_risa_pc4.png'
// import fv_bg_sp from 'assets/img/home/fv_bg_sp.jpg'
import fv_bg_sp from 'assets/img/home/fv_bg_sp3.jpg'
import logotype from 'assets/img/common/logo/rt_logotype_wh.svg'
import FvDecoLine from './FvDecoLine'


// その他
type Props = {
}

export default function FirstView(props: Props) {

  /*=======================================
    アニメーションの制御
  =======================================*/
  useEffect(() => {

    addClass('.fv_overlay', 'active');

    // transition-delayを設定してもよいが、そうすると画面幅を変えた時の挙動が不自然になるため、それぞれ手動で制御
    setTimeout(() => {
      addClass('.fv_bg', 'active');
      addClass('.fv_bg_sp', 'active');
      addClass('.fv_artist_image', 'active');
    }, 200);

    setTimeout(() => {
      addClass('.fv_logo', 'active');
    }, 1700);

    setTimeout(() => {
      addClass('#header', 'active');
    }, 2500);

    // setTimeout(() => {
    //   addClass('.fv_decoration', 'active');
    // }, 2500);

    setTimeout(() => {
      addClass('#l__funclub_button', 'active');
    }, 3500);

  }, []);

  /*=======================================
    ヘッダーの背景制御
  =======================================*/
  useEffect(() => {
    const header_inner = qs('#header');
    // const header_symbol = qs('#header_symbol');
    const targetSelector = '#first_view';
    const options = {
      rootMargin: "0px 0px 0px 0px",
    };
    const observer = generateISObserver(
      targetSelector,
      options,
      () => { addClass(header_inner, 'on_fv');  },
      () => { removeClass(header_inner, 'on_fv') }
    )
    return () => {
      removeClass(header_inner, 'on_fv');
      observer?.disconnect();
    };
  }, []);

  return (
    <>
      <div id="first_view">
        <div className="fv_overlay"></div>
        <img className="fv_bg" src={fv_bg_pc} alt="" decoding="async" loading="lazy" />
        {/* <img className="fv_bg_sp" src={fv_bg_sp} alt="" decoding="async" loading="lazy" /> */}
        {/* <FvDecoLine /> */}
        {/* <img className="fv_artist_image" src={fv_risa4} alt="" decoding="async" loading="lazy" /> */}
        <img className="fv_logo" src={logotype} alt="" decoding="async" loading="lazy" />
      </div>
    </>
  );
}
