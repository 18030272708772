export const SITE_TITLE = "紡木吏佐 OFFICIAL WEBSITE【公式サイト】";
export const SITE_URL_BASE = "https://risatsumugi.jp";
export const SITE_DESCRIPTION_BASE = "声優 紡木吏佐(つむぎりさ・Risa Tsumugi)のオフィシャルサイト。アニメやゲームの出演情報、イベントの情報、お知らせなどなど、随時更新中！";
export const DAY_NAMES = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const API_BASE_URL = backendApiBase() + "/wp-json/wp/v2";

function backendApiBase() {
  const dev = 'http://risa-tsumugi-official-backend.local'
  const prod = 'https://4265352b3f80f8c.main.jp'
  return process.env.REACT_APP_CURRENT_ENV === "production" ? prod : dev
}