// React
import { useEffect, useState } from 'react'
import dayjs from "dayjs";
import { dayNameColor, eventCategoryBackground, eventCategoryColor, getCategoryNumber, getEventCategoryBackground, getEventCategoryColor } from 'modules/projectModules'
import { useModal } from 'react-hooks-use-modal';
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { addStalkerHoverEvent, removeStalkerHoverEvent } from "modules/mouseStalker"
import useScheduleCategoryColor from 'hooks/useScheduleCategoryColor';

type Props = {
  dateInfo: any
}

export default function DateItem(props: Props) {

  const scheduleCategoryColor = useScheduleCategoryColor().scheduleCategory;;

  const thisDate = dayjs(props.dateInfo.date)

  // const [modalDate, setModalDate] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalCategory, setModalCategory] = useState("");
  const [modalDescription, setModalDescription] = useState("");

  const [Modal, modalOpen, modalClose, isOpen] = useModal('root', {
    // trueにするとiphoneでモーダルの中身までスクロールできなくなるためfalseに
    // 背景のスクロール停止は#yieldにoverflow:hiddenを付けることで実現しようとしたが、
    // hiddenした瞬間スクロール位置が一番上まで戻ってしまうため断念
    // そのため背景のスクロール停止自体も断念
    preventScroll: false,
    focusTrapOptions: {
    },
    components: {
      Wrapper: ({ children }) => {
        return (
          <div
            id="l__modal_wrapper"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}
          >
            {children}
          </div>
        );
      },
      Overlay: () => {
        return (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: '#F4FBFD',
              opacity: 0.5,
            }}
          />
        );
      },
      Modal: ({ children }) => {
        return (
          <div
            onClick={() => { }}
            style={{
              pointerEvents: 'auto',
            }}
          >
            {children}
          </div>
        );
      },
    },
  });



  function handleModalOpen(title: any, category: any, description: any) {
    setModalTitle(title);
    setModalCategory(category);

    // description内部のaタグにtarget="_blank"とtabindex="-1"を付与
    // modal open時に自動でフォーカスが当たり、勝手にスクロールされてしまうのを防ぐため
    setModalDescription(description.replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" tabindex="-1" '));

    modalOpen();

    // aタグに対してホバーの反応と外部リンクで開く設定を追加
    const selector: string = ".c__article_body a"
    setTimeout(() => {
      addStalkerHoverEvent(selector);
      const anchors = qsAll(selector);
      for (const anchor of anchors) {
        anchor.setAttribute("target", "_blank");
        anchor.setAttribute("rel", "noopener noreferrer");
      }
    }, 500);
  }

  function handleModalClose(e: any) {

    // イベントハンドラが装着された要素とイベントが発生した要素比較
    // モーダルの中身がクリックされた場合は無効化する
    if (e.target !== e.currentTarget) return;

    // aタグに対してホバーの反応と外部リンクで開く設定を削除
    removeStalkerHoverEvent(".c__article_body a");

    if (e.target.classList.contains("modal_overlay_for_click") || e.target.classList.contains("close_button")) {
      addClass("#l__modal_wrapper", "fadeout");
      setTimeout(() => {
        modalClose();
      }, 500);
    }
  }

  function getCurrentDayClass() {
    if (props.dateInfo.today) return "today";
    if (!props.dateInfo.currentMonth) return "other_month";
    return "";
  }

  return (
    <div className={`date_item ${getCurrentDayClass()}`} id={props.dateInfo.today ? "today" : ""}>
      <div className="date_item_wrapper">
        <p className={`date`}>
          {thisDate.format("D")}
        </p>
        <p className={`day_name u__pc_none ${dayNameColor(props.dateInfo.dayName)}`}>
          {props.dateInfo.dayName}
        </p>
      </div>

      {props.dateInfo.event && props.dateInfo.currentMonth &&
        <div className="event_wrapper">
          {
            props.dateInfo.event?.map((event: any, index: number) => {
              // イベントのカテゴリーのorderを取得
              let categoryColorOrder = getCategoryNumber(scheduleCategoryColor, event.category)
              return (
                <div className={`event ${getEventCategoryBackground(categoryColorOrder)}`} key={index} onClick={() => handleModalOpen(event.title, event.category, event.description)}>
                  {`【${event.category}】${event.title}`}
                </div>
              )
            })
          }
        </div>
      }

      <Modal>

        {/* モーダルを閉じる用のオーバーレイ */}
        <div className="modal_overlay_for_click" onClick={(e) => handleModalClose(e)}>

          <div className="event_modal">
            <div className="modal_inner">

              <div className="article_head_info">
                <div className="info_above">
                  <div className="date">
                    {thisDate.format("YYYY.MM.DD")}
                  </div>
                  <div className={"category" + getEventCategoryColor(getCategoryNumber(scheduleCategoryColor, modalCategory))}>
                    {modalCategory}
                  </div>
                </div>
                <h3 className="title">{modalTitle}</h3>
              </div>

              <div className="c__article_body" dangerouslySetInnerHTML={{ __html: modalDescription }} />

            </div>
            <button className="close_button" onClick={(e) => handleModalClose(e)}>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </Modal>

    </div>
  );
};