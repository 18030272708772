

import { Errors } from 'components/pages/contact/Types'



/*=======================================
  プロジェクト固有バリデーション
=======================================*/

// 名前
export function validateName(value: string, errors: Errors) {
  const itemName = "お名前";
  let msg: Array<String> = [];
  if (value) {
    msg.push(validateLength(value, 60))
  } else {
    msg.push(validatePresence(value, itemName))
  }
  const composedMessage = composeErrorMessage(msg)
  errors.name = composedMessage;
  if (composedMessage) errors.errorItems.push(itemName);
}

// 組織名
export function validateSubject(value: string, errors: Errors) {
  const itemName = "件名";
  let msg: Array<String> = [];
  msg.push(validateLength(value, 60))
  const composedMessage = composeErrorMessage(msg)
  errors.subject = composedMessage;
  if (composedMessage) errors.errorItems.push(itemName);
}

// メールアドレス
export function validateEmail(value: string, errors: Errors) {
  const itemName = "メールアドレス";
  let msg: Array<String> = [];
  if (value) {
    msg.push(validateLength(value, 256))
    msg.push(validateEmailFormat(value, itemName))
  } else {
    msg.push(validatePresence(value, itemName))
  }
  const composedMessage = composeErrorMessage(msg)
  errors.email = composedMessage;
  if (composedMessage) errors.errorItems.push(itemName);
}

// 詳細
export function validateDetails(value: string, errors: Errors) {
  const itemName = "お問い合わせ内容";
  let msg: Array<String> = [];
  if (value) {
    msg.push(validateLength(value, 1000))
    msg.push(validateJapanese(value))
  } else {
    msg.push(validatePresence(value, itemName))
  }
  const composedMessage = composeErrorMessage(msg)
  errors.details = composedMessage;
  if (composedMessage) errors.errorItems.push(itemName);
}

// 規約への同意
export function validateAgreement(checked: boolean, errors: Errors) {
  const itemName = "プライバシーポリシー";
  let msg: Array<String> = [];
  if (!checked) {
    msg.push("プライバシーポリシーへのご同意をお願いします。");
  };
  const composedMessage = composeErrorMessage(msg)
  errors.agreement = composedMessage;
  if (composedMessage) errors.errorItems.push(itemName);
}

/*=======================================
  共通関数
=======================================*/

// 存在チェック
export function validatePresence(value: string, itemName: string): string {
  if (value?.length) return "";
  return itemName + "をご入力ください。";
}
// maxlengthチェック
export function validateLength(value: string, maxlength: number): string {
  if (!value) return "";
  if (value.length < maxlength) return "";
  return maxlength + "文字以内でご入力ください。";
}
// 選択肢チェック
export function validateChoice(value: string, choices: Array<String>): string {
  if (choices.includes(value)) return "";
  return "選択肢からお選びください。"
}
// メールアドレス形式チェック
export function validateEmailFormat(value: string, itemName: string): string {
  if (value?.match(/.+@.+\..+/)) return "";
  return itemName + "の形式をご確認ください。";
}
// 日本語10文字バリデーション
export function validateJapanese(value: string): string {
  if (value?.match(/[ぁ-ゖァ-ヶ「」ー～〜、。・￥／＼（）！？＠：※々…〒↑→↓←一-龠]{10,}/)) return "";
  return "スパム対策のため、最低10文字以上の連続した日本語をご入力ください。";
}
// 各項目のエラーメッセージについて、空要素を削除して結合
function composeErrorMessage(msg: Array<String>) {
  return msg.filter(Boolean).join("また、");
}