
type Props = {
  children: React.ReactNode
  modifier?: string
}

export default function SecondaryHeading(props: Props) {

  function getmodifier() {
    return props.modifier ? props.modifier : ""
  }

  return (
    <h3 className={`c__head_secondary ${getmodifier()}`}>
      {props.children}
    </h3>
  );
}
