import { isMobile } from 'react-device-detect';
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "./qs";

/*=======================================
  マウスストーカー用
=======================================*/
export function addStalkerHoverEvent(selector: string, waitTime: number = 1000): void {
  const addingClass = "stalker_cursor_pointer"

  setTimeout(() => {
    let links = qsAll(selector);
    if (links) {
      let target = qs("#mouse_stalker")
      for (const elm of links) {
        elm.addEventListener('mouseover', function () {
          addClass(target, addingClass);
        });
        elm.addEventListener('mouseout', function () {
          removeClass(target, addingClass);
        });
      }
    }
  }, waitTime);
}
export function removeStalkerHoverEvent(selector: string): void {
  const addingClass = "stalker_cursor_pointer"
  removeClass(qs("#mouse_stalker"), addingClass);
  let links = qsAll(selector);
  if (links) {
    let target = qs("#mouse_stalker")
    for (const elm of links) {
      elm.removeEventListener('mouseover', function () {
        addClass(target, addingClass);
      });
      elm.removeEventListener('mouseout', function () {
        removeClass(target, addingClass);
      });
    }
  }
}
export function activateHoverCursor(): void {
  if (!isMobile) {
    addClass(qs("#mouse_stalker"), "stalker_cursor_pointer");
  }
}
export function deactivateHoverCursor(): void {
  if (!isMobile) {
    removeClass(qs("#mouse_stalker"), "stalker_cursor_pointer");
  }
}