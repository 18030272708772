import { useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from "recoil"
import { newsCategoryAtom } from "../atom/GlobalAtom"
import { NewsCategory } from 'components/models/newsCategory/Types';

// 背景のstateを管理
type Background = State & {
  // setJson: () => void;
  // deactivate: () => void;
  setNewsCategory: (arg: NewsCategory[]) => void
};

type State = {
  newsCategory: NewsCategory[];
};

export default function useNewsCategoryColor(): Background {

  const newsCategory = useRecoilValue(newsCategoryAtom);
  const setState = useSetRecoilState(newsCategoryAtom);

  const setNewsCategory = useCallback((arg: NewsCategory[]) => {
    setState(arg);
  }, []);

  // const deactivate = useCallback(() => setIsActive(false), []);

  return {
    newsCategory,
    setNewsCategory, // Add the missing setJson property
  };
}