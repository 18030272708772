
type Props = {
  variant?: string
}

export default function DecoCircle(props: Props) {
  function variant() {
    return props.variant ? " " + props.variant : ""
  }

  function generateCross(baseX: number, baseY: number, delay: number = 0) {
    const offset = 6;
    return (
      <>
        <path className={`l delay${delay}`} d={`M${baseX},${baseY} L${baseX - offset},${baseY}`} />
        <path className={`r delay${delay}`} d={`M${baseX},${baseY} L${baseX + offset},${baseY}`} />
        <path className={`b delay${delay}`} d={`M${baseX},${baseY} L${baseX},${baseY + offset}`} />
        <path className={`u delay${delay}`} d={`M${baseX},${baseY} L${baseX},${baseY - offset}`} />
      </>
    )
  }

  return (
    <>
      <svg id="" xmlns="http://www.w3.org/2000/svg" className="decoration_image" viewBox="0 0 560 540" preserveAspectRatio="none">

        <path className="stroke circle1" d="M512.2,256c0-45.1-11.5-108.1-55.5-160.1C406.6,36.4,362.3,15,280,10.1C210.2,6,135.5,29.3,93.3,64.8	C47.2,103.6,10.2,166,6.3,256C6,355.5,41,406.8,90.3,450.7c47.8,42.6,117.3,59.6,200.2,54.5c82.9-5.1,145.4-58.6,172.2-91.4C489,381.6,512.2,323.3,512.2,256L512.2,256z" />

        <path className="stroke circle2" d="M512.23 255.89C512.23 212.21 500.64 151.23 456.08 100.79C401.81 39.35 360.69 22.51 277.46 17.79C206.96 13.8 131.91 28.62 89.24 63.02C42.68 100.55 4.76996 168.77 0.779961 255.89C-3.45004 348.26 40.53 425.6 80.97 451.46C134.84 485.91 204.29 502.23 288.09 497.31C371.89 492.39 435.46 439.77 463.39 409.01C491.32 378.25 512.22 321.11 512.22 255.89H512.23Z" />

        <path className="fill" d="M104.93 508.63C104.55 508.25 103.91 507.82 103.08 507.77C102.07 507.71 101.56 507.92 100.79 508.61C100.14 509.19 99.6099 509.98 99.5399 510.65C99.4599 511.38 99.7299 512.31 100.45 513.11C101.22 513.95 102.28 514.25 102.86 514.12C103.63 513.95 104.38 513.49 105.07 512.71C105.76 511.93 105.86 510.92 105.83 510.41C105.8 509.9 105.49 509.21 104.92 508.64L104.93 508.63Z" />

        {/* 左下の丸 */}
        <path className="stroke" d="M82 508.44C81.25 507.69 79.99 506.83 78.35 506.73C76.35 506.61 75.35 507.03 73.83 508.38C72.54 509.53 71.5 511.08 71.36 512.41C71.2 513.86 71.73 515.69 73.16 517.27C74.68 518.94 76.78 519.51 77.92 519.26C79.45 518.92 80.93 518.01 82.29 516.48C83.65 514.95 83.84 512.94 83.79 511.93C83.74 510.92 83.12 509.57 81.99 508.44H82Z" />

        {/* 右上の丸 */}
        <path className="stroke" d="M401.895 3.72949C401.145 2.97949 399.885 2.11949 398.245 2.01949C396.245 1.89949 395.245 2.31949 393.725 3.66949C392.435 4.81949 391.525 6.38949 391.255 7.69949C390.795 9.93949 390.855 11.1095 392.295 12.6795C393.815 14.3495 395.965 14.8095 397.815 14.5495C399.855 14.2595 400.825 13.2995 402.185 11.7695C403.545 10.2395 403.735 8.22949 403.685 7.21949C403.635 6.20949 403.015 4.85949 401.885 3.72949H401.895Z" />

        <g className="c__svg_cross quick">
          {generateCross(116, 14, 2)}
        </g>

        <g className="c__svg_cross quick">
          {generateCross(16, 114, 6)}
        </g>

        <g className="c__svg_cross no_animation">
          {generateCross(10, 414, 0)}
        </g>

        <g className="c__svg_cross no_animation">
          {generateCross(416, 18, 0)}
        </g>

        <g className="c__svg_cross quick">
          {generateCross(520, 360, 5)}
        </g>

        <g className="c__svg_cross quick">
          {generateCross(380, 510, 11)}
        </g>

      </svg>

    </>

  );
}