
import x_icon from 'assets/img/common/web_services_icon/x_logo_bk.svg';
import ig_icon from 'assets/img/common/web_services_icon/instagram_cl.svg';
import yt_icon from 'assets/img/common/web_services_icon/youtube_cl.svg';
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'

type Props = {
  variantClassName?: string;
}
export default function SnsIconList(props: Props) {
  return (
    <>
      <div className={`c__sns_service_icon ${props.variantClassName}`}>
        <a className="service_link" href="https://twitter.com/risa_tsumugi" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} >
          <img src={x_icon} className="icon x" alt="youtube" />
        </a>

        <a className="service_link" href="https://www.instagram.com/risa.tsumugi" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} >
          <img src={ig_icon} className="icon ig" alt="youtube" />
        </a>

        <a className="service_link" href="https://www.youtube.com/@risa.tsumugi" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} >
          <img src={yt_icon} className="icon yt" alt="youtube" />
        </a>
      </div>
    </>
  );
}