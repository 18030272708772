// React
import { useEffect } from 'react';
// 外部ライブラリ
import { motion } from "framer-motion"
import { Helmet } from "react-helmet-async"
// 内部ライブラリ
// コンポーネント
import PrimaryButton from "components/ui/PrimaryButton"
// 型定義
// 定数
import { SITE_TITLE, SITE_URL_BASE } from 'config/Constants';
// 画像
// その他
type Props = {
}

export default function NotFound(props: Props) {

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p__notfound" className="l__main_content">
          <div className="content_inner">
            <h2 className="c__head_secondary">404 <span className="u__ib">NOT FOUND</span></h2>
            <p className="c__common_text_primary">
              お探しのページが見つかりませんでした。
            </p>
            <PrimaryButton mode="link" path="/">TOPへ戻る</PrimaryButton >
          </div>

        </main>
      </motion.div>
    </>
  );
}
