// React
import { useMemo, useEffect } from 'react';
// 外部ライブラリ
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join, nl2br } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
// コンポーネント
import PrimaryHeading from 'components/headings/PrimaryHeading'
import PrimaryButton from 'components/ui/PrimaryButton'
import NewsList from 'components/models/news/NewsList'
// 型定義
// 定数
// 画像
// その他
type Props = {
}

export default function Index(props: Props) {

  // アニメーション用
  useEffect(() => {
    const observer = commonAnimationObserver();
    return () => {
      observer?.disconnect();
    };
  }, []);

  const index = useMemo(() => {
    return (
      <>
        <div className="home_news u__content_width_m c__js_fade">
          <PrimaryHeading>News</PrimaryHeading>
          <NewsList limit={3} pagenate={false} category={false} />
          <PrimaryButton mode='link' path="news">View more</PrimaryButton>
        </div>
      </>
    );
  }, [])

  return (
    <>
      {index}
    </>
  );

}