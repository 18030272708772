import { Link } from 'react-router-dom';
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
// React
// 外部ライブラリ
// 内部ライブラリ
// コンポーネント
import Arrow from 'components/ui/commonSvg/Arrow'
// 型定義
// 定数
// 画像

// その他
type Props = {
  mode: string
  path: string
  external?: boolean
  variant?: string
  children?: React.ReactNode
  [key: string]: any;
}

export default function PrimaryButton({ ...props }: Props,) {

  function generate() {

    const variant = props.variant ? " " + props.variant : ""

    if (props.mode === "link") {
      if (props.external) {
        return (
          <a href={props.path} className={"c__primary_button" + variant} target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} {...props}>
            <span className="link_name">{props.children}</span>
            {!props.variant?.includes("no_arrow") &&
              <Arrow variant="" />}
          </a>
        )
      } else {
        return (
          <Link to={props.path} className={"c__primary_button" + variant} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} {...props}>
            <span className="link_name">{props.children}</span>
            {!props.variant?.includes("no_arrow") &&
              <Arrow variant="" />}
          </Link>
        )
      }
    }
    else {
      return (
        <button className={"c__primary_button" + variant} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} {...props} >
          <span className="link_name">{props.children}</span>
          {!props.variant?.includes("no_arrow") &&
            <Arrow variant="" />}
        </button>
      )
    }
  }

  return (
    generate()
  );
}