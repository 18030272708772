// React
import { useEffect } from 'react';
// 外部ライブラリ
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import { Link } from 'react-router-dom';
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join, nl2br, unEscapeHtmlSpecialCharacters } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
// コンポーネント
import PrimaryHeading from 'components/headings/PrimaryHeading'
import SecondaryHeading from 'components/headings/SecondaryHeading'
// 型定義
// 定数
import { SITE_TITLE, SITE_URL_BASE } from 'config/Constants';
// 画像
// その他
type Props = {
}

export default function Index(props: Props) {

  // アニメーション用
  useEffect(() => {
    const observer = commonAnimationObserver();
    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{`利用規約｜${SITE_TITLE}`}</title>
        <meta property="og:url" content={SITE_URL_BASE + "/terms"} />
        <meta property="og:title" content={`利用規約｜${SITE_TITLE}`} />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p__terms" className="l__main_content">
          <PrimaryHeading>Terms</PrimaryHeading>

          <div className="terms_inner">

            <section className="terms_section" id="">
              
              <SecondaryHeading>利用規約</SecondaryHeading>
              
              <div className="article_block">
                <h3 className="section_header">Cookieの利用について</h3>
                <p className="c__common_text_primary">当サイトでは、Googleによるアクセス解析ツール「Google アナリティクス」を利用しており、トラフィックデータ収集のためにCookieを使用しています。<br />
                  このトラフィックデータは匿名で収集されており、個人を特定するものではありません。<br />
                  この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。<br />
                  この規約に関して、詳しくは「
                  <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>こちら</a>
                  」をご覧ください。</p>
              </div>

              <div className="article_block">
                <h3 className="section_header">著作権について</h3>
                <p className="c__common_text_primary">当サイトの記事について、著作権は放棄しておりません。<br />
                  当サイトに存在する文章・画像・動画等の著作物の情報を無断転載することを禁止します。<br />
                  引用の範囲を超えるものについては、法的措置を行います。<br />
                  転載を希望される方は、「
                  <Link to={"/contact"} className="" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                    お問い合わせ
                  </Link>
                  」よりご連絡をお願いします。
                </p>

                <p className="c__common_text_primary">当サイトは著作権の侵害を目的とするものではありません。<br />
                  使用している版権物の知的所有権はそれぞれの著作者・団体に帰属しております。<br />
                  著作権や肖像権に関して問題がありましたらご連絡下さい。<br />
                  著作権所有者様からの警告及び修正・撤去のご連絡があった場合は迅速に対処または削除致します。</p>
              </div>

              <div className="article_block">
                <h3 className="section_header">免責事項</h3>
                <p className="c__common_text_primary">
                  当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。<br />
                  当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございますので予めご了承ください。<br />
                  当サイトに掲載された内容によって生じた損害等につきましても、一切の責任を負いかねますのでご了承ください。
                </p>
              </div>
            </section>


            <section className="terms_section" id="">
              <SecondaryHeading>プライバシーポリシー</SecondaryHeading>

              <div className="article_block">
                <p className="c__common_text_primary">
                  当サイトにおけるユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます）を定めます。</p>
              </div>

              <div className="article_block">
                <h3 className="section_header">第1条（個人情報）</h3>
                <p className="c__common_text_primary">「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、
                  当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、
                  指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。</p>
              </div>

              <div className="article_block">
                <h3 className="section_header">第2条（個人情報の収集方法）</h3>
                <p className="c__common_text_primary">当サイトはサービスを提供するために、必要な範囲内で適法かつ適正な方法によりお客様の個人情報を収集致します。</p>
              </div>

              <div className="article_block">
                <h3 className="section_header">第3条（個人情報を収集・利用する目的）</h3>
                <p className="c__common_text_primary">当サイトが個人情報を収集・利用する目的は、以下のとおりです。</p>
                <ul>
                  <li>ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</li>
                  <li>利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため</li>
                  <li>上記の利用目的に付随する目的</li>
                </ul>
              </div>

              <div className="article_block">
                <h3 className="section_header">第4条（利用目的の変更）</h3>
                <p className="c__common_text_primary">当サイトは、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。</p>
              </div>

              <div className="article_block">
                <h3 className="section_header">第5条（個人情報の第三者提供）</h3>
                <p className="c__common_text_primary">当サイトは、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、
                  第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。</p>
                <ul>
                  <li>人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
                  <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
                  <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、
                    本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
                  <li>予め次の事項を告知あるいは公表し、かつ当サイトが個人情報保護委員会に届出をしたとき</li>
                  <li>利用目的に第三者への提供を含むこと</li>
                  <li>第三者に提供されるデータの項目</li>
                  <li>第三者への提供の手段または方法</li>
                  <li>本人の求めに応じて個人情報の第三者への提供を停止すること</li>
                  <li>本人の求めを受け付ける方法</li>
                  <li>前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。</li>
                  <li>当サイトが利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
                  <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
                  <li>個人情報を特定の者との間で共同して利用する場合であって、
                    その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、
                    利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、
                    あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合</li>
                </ul>
              </div>

              <div className="article_block">
                <h3 className="section_header">第6条（個人情報の開示）</h3>
                <p className="c__common_text_primary">
                  当サイトは、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。<br />
                  ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、
                  開示しない決定をした場合には、その旨を遅滞なく通知します。<br />
                  なお、個人情報の開示に際しては、個人情報保護法に基づき1件あたり700円の手数料を申し受けます。</p>
                <ul>
                  <li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
                  <li>当サイトの業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
                  <li>その他法令に違反することとなる場合</li>
                  <li>前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。</li>
                </ul>
              </div>

              <div className="article_block">
                <h3 className="section_header">第7条（個人情報の訂正および削除）</h3>
                <p className="c__common_text_primary">
                  ユーザーは、当サイトの保有する自己の個人情報が誤った情報である場合には、当サイトが定める手続きにより、
                  当サイトに対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。<br />
                  当サイトは、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。<br />
                  当サイトは、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザーに通知します。
                </p>
              </div>

              <div className="article_block">
                <h3 className="section_header">第8条（個人情報の利用停止等）</h3>
                <p className="c__common_text_primary">当サイトは、本人から、個人情報が利用目的の範囲を超えて取り扱われているという理由、
                  または不正の手段により取得されたものであるという理由により、
                  その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。<br />
                  前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。
                </p>
                <p className="c__common_text_primary">当サイトは、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。<br />
                  前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、
                  ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。
                </p>
              </div>

              <div className="article_block">
                <h3 className="section_header">第9条（プライバシーポリシーの変更）</h3>
                <p className="c__common_text_primary">本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。<br />
                  当サイトが別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
                </p>
              </div>

              <div className="article_block">
                <h3 className="section_header">第10条（お問い合わせ窓口）</h3>
                <p className="c__common_text_primary">本ポリシーに関するお問い合わせは、「
                  <Link to={"/contact"} className="" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                    お問い合わせ
                  </Link>
                  」までお願いいたします。</p>
              </div>

              <div className="article_block">
                <h3 className="section_header">制定日・最終改定日</h3>
                <p className="c__common_text_primary">
                  ・制定日 - 2023/11/01<br />
                  ・最終改定日 - 2023/11/01
                </p>
              </div>
            </section>
          </div>
        </main>
      </motion.div>
    </>
  );
}
