import { NewsCategory } from "components/models/newsCategory/Types";
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "./qs";
import { ScheduleCategory } from "components/models/scheduleCategory/Types";

// export function newsCategoryColor(name: string) {
//   if (name == "All") return " u__color_blue"
//   if (name == "Info") return " u__color_purple"
//   if (name == "Voice") return " u__color_green"
//   if (name == "Media") return " u__color_pink"
//   if (name == "Live / Event") return " u__color_yellow"
//   return " u__color_gray"
// }
const colorList = ["blue", "purple", "green", "pink", "yellow", "coral", "category_lightgreen" ]

// 数字が1～7のうち何の約数なのか計算する
function calcDivisor(order: number) {
  const divisor = order % 7;
  return divisor;
}

export function getNewsCategoryColorClass(order: number | null) {
  // let color = "u__color_gray";

  // orderが1～7のうち何の約数なのか計算する
  // const orderNum = parseInt(order);
  // const colorNum = orderNum % 6;
  if (order === null) return " u__color_gray";
  return ` u__color_${colorList[calcDivisor(order)]}`;
  // return ` ${color}`
}
export function getEventCategoryColor(order: number | null) {
  // let color = "u__color_gray";

  // orderが1～7のうち何の約数なのか計算する
  // const orderNum = parseInt(order);
  // const colorNum = orderNum % 6;
  if (order === null) return " u__color_gray";
  return ` u__color_${colorList[calcDivisor(order)]}`;
  // return ` ${color}`
}
export function getEventCategoryBackground(order: number | null) {
  // let color = "u__color_gray";

  // orderが1～7のうち何の約数なのか計算する
  // const orderNum = parseInt(order);
  // const colorNum = orderNum % 6;
  if (order === null) return " u__background_gray";
  return ` u__background_${colorList[calcDivisor(order)]}`;
  // return ` ${color}`
}

export function eventCategoryColor(name: string) {
  if (name == "Anime") return " u__color_blue"
  if (name == "Info") return " u__color_purple"
  if (name == "Media") return " u__color_pink"
  if (name == "Live / Event") return " u__color_yellow"
  if (name == "Event") return " u__color_yellow"
  return " u__color_gray"
}

export function eventCategoryBackground(name: string) {
  if (name == "Anime") return " u__background_blue"
  if (name == "Info") return " u__background_purple"
  if (name == "Media") return " u__background_pink"
  if (name == "Live / Event") return " u__background_yellow"
  if (name == "Event") return " u__background_yellow"
  return " u__background_gray"
}

export function historyCategoryColor(name: string) {
  if (name == "Anime") return " u__color_blue"
  if (name == "Game") return " u__color_purple"
  if (name == "Live") return " u__color_yellow"
  if (name == "Others") return " u__color_green"
  return " u__color_gray"
}

export function dayNameColor(name: string) {
  if (name == "Sat") return " u__color_blue"
  if (name == "Sun") return " u__color_pink"
  return " u__color_gray"
}

// 改行コードごとにliタグで囲み、jsxにする関数
export function nl2li(text: string): any {
  if (!text) return "";
  const regex = /(\n)/g;
  const result = text.split(regex).map((line, index) => {
    if (!line.match(regex)) {
      return <li key={index}>{line}</li>;
    }
  });
  return result;
}



// 取得したカテゴリの配列に連番を割り振り、カテゴリ名と番号の関係を定義したjsonを返す
export function defineCategoryNumber(categories: string[], origin: number): NewsCategory[] {
  const ret: NewsCategory[] = [];
  categories.forEach((category: any, index) => {
    const json: NewsCategory = {
      id: category.id,
      name: category.name,
      order: index + origin
    }
    ret.push(json);
  });
  return ret;
}

// カテゴリ名からカテゴリ番号を取得
export function getCategoryNumber(categories: ScheduleCategory[], name: string): number {
  const category = categories.find((category) => category.name === name);
  return category ? category.order : 0;
}