// React
// 外部ライブラリ
// 内部ライブラリ
// コンポーネント
// 型定義
// 定数
// 画像
// その他
type Props = {
}

export default function ActiveCategoryLine(props: Props) {
  return (
    <svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" x="0px"
      y="0px" viewBox="0 0 64 4" preserveAspectRatio="none">
      <path id="Vector" className="st0" d="M0.1,2.2C9.2,0,18.6,0.1,32.3,2.3C46.1,4.5,56.8,2.7,63.9,1" />
    </svg>
  );
}