import dayjs from "dayjs";
// React
import { useEffect, useState } from 'react'
// 外部ライブラリ
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import axios from 'axios';
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { nl2br } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
import { getNewsCategoryColorClass, historyCategoryColor, nl2li } from 'modules/projectModules'
// コンポーネント
import PrimaryHeading from 'components/headings/PrimaryHeading'
import CalendarHeader from "./CalendarHeader";
import MonthCalender from "./MonthCalender";
import PrimaryButton from "components/ui/PrimaryButton";
// 型定義
// 定数
import { SITE_TITLE, SITE_URL_BASE, API_BASE_URL } from 'config/Constants';
// 画像
// 型定義

const today: dayjs.Dayjs = dayjs();
const prevLimit: dayjs.Dayjs = today.add(-8, 'month') // 7ヶ月前まで遡れる(dayjsのmonthIndexが0始まりなので-7を設定)
const nextLimit: dayjs.Dayjs = today.add(7, 'month') // 7ヶ月後まで進められる

type Props = {
}

export default function Calender(props: Props) {

  // const [targetYear, setTargetYear] = useState<dayjs.Dayjs>(today); //必要かどうか確認する
  // const [targetMonth, setTargetMonth] = useState<dayjs.Dayjs>(today);
  const [targetDate, setTargetDate] = useState<dayjs.Dayjs>(today);

  const handlePrevMonth = () => {
    console.log("targetMonth", today)
    console.log("targetMonth", today.add(-1, "month"))
    const target = qs(".month_calender_wrapper")
    addClass(target, "prev_switched");
    setTimeout(() => {
      setTargetDate(targetDate.add(-1, "month"));
      // setTargetMonth(targetMonth.add(-1, "month"));
    }, 400);
    setTimeout(() => {
      removeClass(target, "prev_switched");
    }, 800);
  };
  const handelNextMonth = () => {
    const target = qs(".month_calender_wrapper")
    addClass(target, "next_switched");
    setTimeout(() => {
      setTargetDate(targetDate.add(1, "month"));
      // setTargetMonth(targetMonth.add(1, "month"));
    }, 400);
    setTimeout(() => {
      removeClass(target, "next_switched");
    }, 800);
  };
  const handleReset = () => {
    const target = qs(".month_calender_wrapper")
    addClass(target, "reset");
    setTimeout(() => {
      // 現在の月をセット
      setTargetDate(today);
      removeClass(target, "reset");
    }, 400);
  };

  return (
    <>
      <div className="calender_wrapper c__js_fade">
        <CalendarHeader
          handlePrevMonth={handlePrevMonth}
          handelNextMonth={handelNextMonth}
          handleReset={handleReset}
          targetDate={targetDate}
          // today={today}
          prevLimit={prevLimit}
          nextLimit={nextLimit}
        />
        <MonthCalender
          targetDate={targetDate}
          today={today}
          prevLimit={prevLimit}
          nextLimit={nextLimit}
        />
      </div>
      <PrimaryButton mode="link" path="/" variant="back_to_top_button">Top</PrimaryButton>
    </>
  );
}