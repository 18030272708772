// React
import { useEffect, } from "react";
// 外部ライブラリ
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
// 内部ライブラリ
import useCurrentPage from 'hooks/useCurrentPage'
import { deactivateHoverCursor } from "modules/mouseStalker"
// コンポーネント
import Home from '../components/pages/home/Index';
import NewsIndex from '../components/pages/news/Index';
import NewsShow from '../components/pages/news/Show';
import Contact from '../components/pages/contact/Index';
import ContactSubmitted from '../components/pages/contact/Submitted';
import Profile from '../components/pages/profile/Index';
import Schedule from '../components/pages/schedule/Index';
import Terms from '../components/pages/terms/Index';
import NotFound from '../components/pages/errors/NotFound';
// 型定義
// 定数
// 画像
// その他
type Props = {
}
export default function Router(props: Props) {
  const location = useLocation();

  const currentPage = useCurrentPage();
  useEffect(() => {
    deactivateHoverCursor();
  }, [currentPage]);
  return (
    <>
      <AnimatePresence mode="wait" initial={true} onExitComplete={() => document.documentElement.scrollTop = 0}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<NewsIndex />} />
          <Route path="/news/:id" element={<NewsShow />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/submitted" element={<ContactSubmitted />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AnimatePresence>
    </>
  );
};