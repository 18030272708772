// React
import { useEffect } from 'react';
// 外部ライブラリ
import { motion } from "framer-motion";
// 内部ライブラリ
import { getParam } from 'modules/url'
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join, nl2br, unEscapeHtmlSpecialCharacters } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector, setDvh, setSvh } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
// コンポーネント
import FirstView from './FirstView';
import Information from './Information';
import News from './News';
import VirtualEstateButton from './FanClubButton';
// 型定義
// 定数
// 画像

// その他
type Props = {
}
export default function Index(props: Props) {


  // アニメーション用
  useEffect(() => {
    const observer = commonAnimationObserver();

    // 別ページから戻ってきた際FV用にvhを再設定
    setDvh();
    setSvh();

    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p__home">
          <FirstView />
          <div className="white_bg">
            <News />
            <Information />

          </div>

        </main>
        <VirtualEstateButton />

      </motion.div>
    </>
  );
}