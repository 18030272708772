
type Props = {
  modifier?: string
  children?: React.ReactNode
  [key: string]: any;
}

export default function PrimaryHeading(props: Props) {

  function getmodifier() {
    return props.modifier ? props.modifier : ""
  }

  return (
    <h2 className={`c__head_primary ${getmodifier()}`} {...props}>
      {props.children}
    </h2>
  );
}
