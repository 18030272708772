// React
import { useMemo, useEffect } from 'react';
// 外部ライブラリ
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join, nl2br } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
// コンポーネント
import PrimaryHeading from 'components/headings/PrimaryHeading'
import LongArrow from 'components/ui/commonSvg/LongArrow'
// 型定義
// 定数
// 画像
import x_icon from 'assets/img/common/web_services_icon/x_logo_bk.svg';
import ig_icon from 'assets/img/common/web_services_icon/instagram_cl.svg';
import yt_icon from 'assets/img/common/web_services_icon/youtube_cl.svg';
import Arrow from 'components/ui/commonSvg/Arrow';
import { Link } from 'react-router-dom';



// その他
type Props = {
}

export default function Information(props: Props) {

  return (
    <>
      <div className="home_information u__content_width_m c__js_fade_delay">
        <div className="info_l js__delay_child delay0">
          <PrimaryHeading>Official Social Media</PrimaryHeading>
          <div className="social_media_wrapper">
            <a className="link" href="https://twitter.com/risa_tsumugi" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} >
              <span className="icon"><img src={x_icon} className="x" alt="x" /></span>
              <div className="link_info">
                <p className="link_name">紡木吏佐 公式アカウント</p>
                <p className="id">@risa_tsumugi</p>
                <LongArrow variant="" />
              </div>
            </a>

            <a className="link" href="https://www.instagram.com/risa.tsumugi" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              <span className="icon"><img src={ig_icon} className="ig" alt="instagram" /></span>
              <div className="link_info">
                <p className="link_name">紡木吏佐 公式アカウント</p>
                <p className="id">risa.tsumugi</p>
                <LongArrow variant="" />
              </div>
            </a>

            <a className="link" href="https://www.youtube.com/@risa.tsumugi" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              <span className="icon"><img src={yt_icon} className="yt" alt="youtube" /></span>
              <div className="link_info">
                <p className="link_name">紡木吏佐 公式チャンネル</p>
                <p className="id">@risa.tsumugi</p>
                <LongArrow variant="" />
              </div>
            </a>
          </div>
        </div>
        <div className="info_r">
          <div className="announce js__delay_child delay1">
            <PrimaryHeading>Announce</PrimaryHeading>
            <p className=" c__common_text_primary address_annotation">
              <span className="u__ib">ファンレター、プレゼントは</span><span className="u__ib">下記宛先までお願い致します。</span>
            </p>
            <p className="c__common_text_primary address">
              〒107-0062<br />
              東京都港区南青山4-18-11<br />
              フォレストヒルズイーストウィング 2F<br />
              mitt management株式会社 紡木吏佐 宛
            </p>
            <p className="c__common_text_secondary annotation">※大きなもの・生モノ等はご遠慮ください。</p>
          </div>

          <div className="contact js__delay_child delay2">
            <PrimaryHeading>Contact</PrimaryHeading>
            <div className="contact_info">
              <p className="c__common_text_primary contact_annotation">
                お仕事のご依頼、ご相談は<br className="u__sp_only" />「Contact」<br className="u__pc_only" />
                フォームよりお問い合わせください。
              </p>
              <Link
                to="/contact"
                className={"c__navigation_button circle"}
                onMouseOver={activateHoverCursor}
                onMouseOut={deactivateHoverCursor}
                {...props}
              >
                <Arrow />
              </Link>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
