// React
// 外部ライブラリ
// 内部ライブラリ
// コンポーネント
// 型定義
// 定数
// 画像
// その他
type Props = {
  variant?: string
}

export default function Arrow(props: Props) {
  function variant() {
    return props.variant ? " " + props.variant : ""
  }
  return (
      <svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" x="0px"
      y="0px" viewBox="0 0 42 18" className={"c__svg_arrow" + variant()}>
        <path className="" d="M0,12h36.3c4.9,0,4.5,4.2,1.3,4.2c-5,0-9.3-7.2-12.1-16" />
      </svg>
  );
}