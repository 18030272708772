// React
// 外部ライブラリ
// 内部ライブラリ
// コンポーネント
// 型定義
// 定数
// 画像
// その他
type Props = {
  variant?: string
}

export default function LongArrow(props: Props) {
  function variant() {
    return props.variant ? " " + props.variant : ""
  }
  return (
      <svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" x="0px"
      y="0px" viewBox="0 0 219 18" width="219" height="18" className={"c__svg_long_arrow" + variant()}>
        <path d="M0 12.8H196.187H214.325C219.169 12.8 218.839 17 215.573 17C210.569 17 206.324 9.8 203.488 1"/>
      </svg>
  );
}