/**
* ランダムな正の整数を生成
* @param {number} min - 最小値
* @param {number} max - 最大値
* @param {number} offset - 結果を若干上振れor下振れさせたい場合に使用
* @return {number} ランダムな整数
* @note offsetに小数を渡した場合は小数が返却される
* @note 0が返却される可能性もあり
*/
export function getRandomInteger(min: number, max: number, offset: number = 0) {
  const num = (Math.floor(Math.random() * (max + 1 - min)) + min) + offset;
  return num <= 0 ? 0 : num;
}

/**
* 小数点の桁数をを指定し、四捨五入して返す
* @param {number} number - 対象となる数値 
* @param {number} digit - 小数点の桁数
* @return {number} 四捨五入した値
* @note Math.pow() は累乗を算出する関数で、 Math(x, y) はxのy乗を表す
*/
export function round(number: number, digit: number) {
  const magnification = Math.pow(10, digit);
  return Math.round(number * magnification) / magnification;
};

/**
 * 指定した範囲の数値を配列で返す
 * @param {number} start - 開始値
 * @param {number} end - 終了値
 * @return {number[]} 開始値から終了値までの数値が格納された配列
 * @note startがendより大きい場合は空の配列が返却される
*/
export function range(start: number, end: number): number[] | [] {
  const list = [];
  for (let i = start; i <= end; i++) {
    list.push(i);
  }
  return list;
}