// React
import { useEffect, useState } from 'react'
// 外部ライブラリ
import axios from 'axios'
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { getNewsCategoryColorClass, historyCategoryColor } from 'modules/projectModules'
// コンポーネント
import SecondaryHeading from 'components/headings/SecondaryHeading'
import ActiveCategoryLine from 'components/ui/commonSvg/ActiveCategoryLine'
// 型定義
// 定数
import { API_BASE_URL } from 'config/Constants';
import useNewsCategoryColor from 'hooks/useNewsCategoryColor';

type Props = {
  setCatId: any
  setCurrentPage: any
  catId: string
  categoryTaxonomy: string
}

export default function CategoriesList(props: Props) {

  const categories = useNewsCategoryColor().newsCategory;
  // console.log("categories", categories)
  // const [categories, setCategories] = useState([])
  // const apiDomain = process.env.REACT_APP_BACKEND_API_DOMAIN


  // 初回のみ、ページングのヒストリー操作にまつわるヒストリーAPIの初期設定を実行
  useEffect(() => {
    window.addEventListener("popstate", back)
    return () => {
      cleanPopstate()
    }
  }, [])
  function cleanPopstate() {
    window.removeEventListener("popstate", back)
  }

  // ブラウザバック時に発動、ひとつ前の履歴のカテゴリをセット
  function back(e: any) {
    let category: string = window.history.state.category ? window.history.state.category : ""
    props.setCatId(category)
  }



  // function composeApiUrl(): string {
  //   const query = `/${props.categoryTaxonomy}`;
  //   const uri = API_BASE_URL + query
  //   return uri
  // }

  useEffect(() => {

    // axios({
    //   method: 'get',
    //   url: composeApiUrl(),
    //   timeout: 5000
    // })
    //   .then(function (res) {
    //     setCategories(res.data)
    //   })
    //   .catch(function (error) {
    //   })
    //   .finally(function () {
    //     loaded(".c__categories_list");
    //   });
    loaded(".c__categories_list");

  }, [])

  function handleClick(categoryId: string, e: any) {
    const categoryParam = categoryId == "" ? "" : `&category=${categoryId}`
    props.setCatId(categoryId)
    props.setCurrentPage(1)
    window.history.pushState({ category: categoryId }, '', `?page=${1}${categoryParam}`)
  }

  function composeCategoryClass(id: string | null, index: number) {
    let base = "category_item";
    base += getNewsCategoryColorClass(index);
    if (id == props.catId) base += " active"
    if (!props.catId && index === 0) base += " active"
    return base;
  }


  function showCategories(): any {
    let categoryListDom = [
      <li className={composeCategoryClass(null, 0)} key="all">
        <button className="category_buttons" onClick={(e) => handleClick("", e)} onMouseOver={activateHoverCursor}
          onMouseOut={deactivateHoverCursor}>
          All
        </button>
        <ActiveCategoryLine/>
      </li>
    ]
    if (categories.length >= 1) {
      return (
        categoryListDom.concat(
          categories?.map((category: any, index: number) => {
            return (
              <li className={composeCategoryClass(category.id, category.order)} key={category.id + "category"}>
                <button
                  className="category_buttons"
                  onClick={(e) => handleClick(category.id, e)}
                  onMouseOver={activateHoverCursor}
                  onMouseOut={deactivateHoverCursor}
                >
                  {category.name}
                </button>
                <ActiveCategoryLine />
              </li>
            )
          })
        )
      )
    }
  }

  return (
    <>
      <ul className="c__categories_list js__load_required l__content_width">
        {showCategories()}
      </ul>
    </>
  )
}