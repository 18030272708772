import Arrow from 'components/ui/commonSvg/Arrow'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
// React
// 外部ライブラリ
// 内部ライブラリ
// コンポーネント
// 型定義
// 定数
// 画像
// その他
type Props = {
  variant?: string
  [key: string]: any;
}

export default function NavigationButton({ ...props }: Props,) {

  function variant() {
    return props.variant ? " " + props.variant : ""
  }

  return (
    <button
      className={"c__navigation_button" + variant()}
      onMouseOver={activateHoverCursor}
      onMouseOut={deactivateHoverCursor}
      {...props}
    >
      <Arrow />
    </button>
  )
}