// React
import { useEffect, useState, useMemo } from 'react';
// 外部ライブラリ
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from 'axios';
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { getParam } from 'modules/url'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { getCategoryNumber, getNewsCategoryColorClass, historyCategoryColor } from 'modules/projectModules'
// コンポーネント
import Pagenation from '../../ui/Pagenation'
import CategoriesList from '../newsCategory/CategoriesList'
import Arrow from 'components/ui/commonSvg/Arrow'
// 型定義
// 定数
import { API_BASE_URL } from 'config/Constants';
import useNewsCategoryColor from 'hooks/useNewsCategoryColor';
// 画像


type Props = {
  limit: number
  pagenate: boolean
  category: boolean
}

export default function NewsList(props: Props) {


  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  // 記事セット
  const [articles, setArticleList] = useState([]);

  // カテゴリカラー
  const newsCategoryColor = useNewsCategoryColor().newsCategory;

  // ページング系
  const limit = props.limit;
  const [currentPage, setCurrentPage] = useState(initCurrentPage());
  const [totalPages, setTotalPages] = useState(0);
  const [catId, setCatId] = useState(initCategory());
  const [routeUniqueKey, setRouteUniqueKey] = useState(`${Math.random()}`);

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const categoryParam = catId === "" ? "" : `category=${catId}&`
    const query = `/article_list?${categoryParam}limit=${limit}&page=${currentPage}${previewModeQuery}`
    const uri = API_BASE_URL + query
    return uri
  }

  // 初回画面表示時に選択されているページ数を取得
  function initCurrentPage(): number {
    let params: number = parseInt(getParam("page"));
    if (params) {
      return params
    } else {
      return 1
    }
  }

  // 初回画面表示時に選択されているカテゴリを取得
  function initCategory(): string {
    let params: string = getParam("category");
    if (params) {
      return params
    } else {
      return ""
    }
  }

  // 記事一覧を取得してセット
  useEffect(() => {
    loading(".c___news_list");

    axios({
      method: 'get',
      url: composeApiUrl(),
      timeout: 5000
    })
      .then(function (res) {
        setTimeout(() => {
          setArticleList(res.data.contents);
          setTotalPages(parseInt(res.data.total_pages)); //総ページ数 
        }, 500);
      })
      .catch(function (error) {
      })
      .finally(function () {
        loaded(".c___news_list");
      });
  }, [catId, currentPage, routeUniqueKey]);


  const showArticles = useMemo(() => {
    let postTitle;
    let postUrl;
    let publishedDate;
    let postCategory: any;
    let categoryColorOrder: number;
    let categoryColorClass: string;
    let previewQuery = getParam("previewMode") === "on" ? "?previewMode=on" : "";

    if (articles?.length >= 1) {
      return (

        <>
          {
            articles.map((article: any, index: number) => {
              // タイトルの取得
              postTitle = String(article.title);
              // URLの生成
              postUrl = "/news/" + article.id + previewQuery;
              //公開日
              publishedDate = dayjs(article.date).format('YYYY.MM.DD');

              postCategory = article.cat_names[0]

              categoryColorOrder = getCategoryNumber(newsCategoryColor, postCategory)
              categoryColorClass = getNewsCategoryColorClass(categoryColorOrder)
              return (

                <Link to={postUrl} className="news_item" title={postTitle} key={index} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                  <div className="sub_info">
                    <div className="date">
                      {publishedDate}
                    </div>
                    <div className={"category" + categoryColorClass}>
                      {postCategory}
                    </div>
                  </div>
                  <div className="title">{postTitle}</div>
                  <Arrow variant="" />

                </Link>

              )
            })}

        </>
      )
    } else {
      return (
        <p className="article_notfound c__common_text_primary">
          <span className="u__ib">データが見つかりませんでした。</span>
        </p>
      )
    }
  }, [articles])

  return (
    <>
      {props.category &&
        <CategoriesList categoryTaxonomy="article_category" catId={catId} setCatId={setCatId} setCurrentPage={setCurrentPage} />}

      <div className=" c___news_list js__load_required" key={routeUniqueKey}>
        {showArticles}
      </div>
      {props.pagenate && totalPages >= 2 &&
        <Pagenation
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages} />}
    </>
  );
}