

export function getParam(param: string): string {
  try {
    let url = new URL(window.location.href);
    let value = url.searchParams.get(param);
    return value ? value : "";
  }
  catch (e) {
    return "";
  }
}
export function getParamFromUrlString(urlString: string, param: string): string {
  try {
    let url = new URL(urlString);
    let value = url.searchParams.get(param);
    return value ? value : "";
  }
  catch (e) {
    return "";
  }
}
export function getPath(): string {
  return window.location.pathname;
}
export function getHash(): string {
  return window.location.hash;
}
export function getPathHash(): string {
  return window.location.pathname + window.location.hash;
}
