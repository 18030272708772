// React
// 外部ライブラリ
// 内部ライブラリ
// コンポーネント
// 型定義
// 定数
// 画像
// その他
type Props = {
  variant?: string
}

export default function SymbolMark(props: Props) {

  return (
    <>
      <svg version="1.1" id="header_symbol" xmlns="http://www.w3.org/2000/svg" x="0px"
        y="0px" viewBox="0 0 68 60" >
        <g>
          <path d="M52.1,4.1c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C50.2,3.3,51.1,4.1,52.1,4.1z" />
          <path d="M29.4,40.6c-1.7-0.8-4.5-0.7-7.4,1.8c-0.1,0.1-0.2,0.1-0.3,0c-2.1-1.7-4.9-3.8-5.1-3.9c0,0-2.5-1.9-4.5-3.5
		c-0.1-0.1-0.1-0.2,0-0.3c2-1.6,4.5-3.5,4.5-3.5c0.2-0.1,3-2.3,5.1-4c0.1,0,0.1,0,0.2,0c2.8,2.5,5.7,2.6,7.4,1.8
		c1.5-0.7,2.4-2,2.4-3.6c0-1.6-0.9-2.9-2.4-3.6c-1.7-0.8-4.6-0.7-7.4,1.8c0,0-0.1,0-0.2,0c-2.1-1.7-4.9-3.8-5.1-4
		c0,0-2.5-1.9-4.5-3.5c-0.1-0.1-0.1-0.2,0-0.2C15.6,13,22.2,7.8,27.2,4c0.1,0,0.1-0.1,0.2-0.1C28.4,3,29.7,3,30.2,3
		c0.1,0,0.2-0.1,0.1-0.2l-1.2-1.6h0l0,0c-0.3-0.4-0.7-1-0.9-1.2C28.1,0,28,0,28,0c-1.5,1.1-13.2,10.2-18,14c-0.1,0-0.1,0-0.2,0
		c-2.8-2.5-5.7-2.6-7.4-1.8c-1.5,0.7-2.4,2-2.4,3.6c0,1.6,0.9,2.9,2.4,3.6c1.7,0.8,4.5,0.7,7.3-1.7h0c0.1-0.1,0.2-0.1,0.3,0
		c2.1,1.7,4.9,3.8,5,3.9c0,0,2.5,1.9,4.5,3.5c0.1,0.1,0.1,0.2,0,0.3c-2,1.6-4.5,3.5-4.5,3.5c-0.2,0.1-3,2.3-5.1,4
		c-0.1,0-0.1,0-0.2,0c-2.8-2.5-5.7-2.6-7.4-1.8c-1.5,0.7-2.4,2-2.4,3.6c0,1.6,0.9,2.9,2.4,3.6c1.7,0.8,4.6,0.7,7.4-1.8
		c0.1,0,0.1,0,0.2,0c2.1,1.7,4.9,3.8,5.1,4c0,0,2.5,1.9,4.5,3.5c0.1,0.1,0.1,0.2,0,0.2c-3.5,2.7-10,7.8-14.9,11.6
		C4.6,56,4.5,56,4.4,56.1c-1.1,0.9-2.3,0.9-2.9,0.9c-0.1,0-0.2,0.1-0.1,0.2L2.1,58c0,0,0,0.1,0,0.1c0.3,0.4,1.1,1.5,1.4,1.8
		c0,0.1,0.1,0.1,0.2,0c1.5-1.1,13.2-10.2,18-14c0.1,0,0.1,0,0.2,0c2.8,2.5,5.7,2.6,7.4,1.8c1.5-0.7,2.4-2,2.4-3.6
		C31.8,42.6,30.9,41.3,29.4,40.6z M24.1,25.2c1.7-1.3,3.3-1.4,4.2-1c0.4,0.2,0.8,0.5,0.8,1.2s-0.4,1-0.8,1.2c-0.9,0.4-2.4,0.3-4.2-1
		C24.1,25.4,24.1,25.2,24.1,25.2z M7.6,16c-1.7,1.4-3.3,1.4-4.2,1c-0.4-0.2-0.8-0.5-0.8-1.2c0-0.7,0.4-1,0.8-1.2
		c0.3-0.1,0.7-0.2,1.1-0.2c0.8,0,2,0.3,3.1,1.2C7.7,15.8,7.7,15.9,7.6,16L7.6,16z M7.6,34.9c-1.7,1.4-3.3,1.4-4.2,1
		c-0.4-0.2-0.8-0.5-0.8-1.2c0-0.7,0.4-1,0.8-1.2c0.3-0.1,0.7-0.2,1.1-0.2c0.8,0,2,0.3,3.1,1.2C7.7,34.7,7.7,34.8,7.6,34.9L7.6,34.9z
		 M28.3,45.3c-0.9,0.4-2.4,0.3-4.2-1c-0.1-0.1-0.1-0.2,0-0.3c1.7-1.4,3.3-1.4,4.2-1c0.4,0.2,0.8,0.5,0.8,1.2
		C29.1,44.8,28.7,45.1,28.3,45.3z"/>
          <path d="M65.5,40.5c-1.7-0.8-4.5-0.7-7.3,1.7v0c-0.1,0.1-0.3,0-0.3,0c-2.1-1.7-4.9-3.8-5-3.9c0,0-2.5-1.9-4.5-3.5
		c-0.1-0.1-0.1-0.2,0-0.3c3.4-2.6,9.5-7.4,14.2-11c0.1,0,0.1-0.1,0.2-0.1c0.9-0.7,1.7-0.9,2.8-0.9c0.1,0,0.1-0.2,0.1-0.2l-0.6-0.8
		c0,0,0,0,0,0c-0.1-0.1-0.2-0.3-0.4-0.5l-0.2-0.3l0,0c-0.3-0.4-0.6-0.8-0.8-1c0-0.1-0.1-0.1-0.2,0c-1.4,1.1-12.5,9.7-17.1,13.3
		c-0.1,0-0.1,0-0.2,0c-2.8-2.5-5.7-2.6-7.4-1.8c-1.5,0.7-2.4,2-2.4,3.6c0,1.6,0.9,2.9,2.4,3.6c1.7,0.8,4.5,0.7,7.4-1.8
		c0.1-0.1,0.2-0.1,0.3,0c2.1,1.7,4.9,3.8,5,3.9c0,0,2.5,1.9,4.5,3.5c0.1,0.1,0.1,0.2,0,0.3c-3.5,2.8-10.2,8-15.2,11.9
		c-1.1,0.8-2.3,0.9-2.8,0.8c-0.1,0-0.2,0.1-0.1,0.2l0.7,0.9c0,0,0,0,0,0.1l1.4,1.8c0,0,0.1,0.1,0.2,0c1.5-1.1,13.2-10.2,18-14
		c0.1,0,0.1,0,0.2,0c1.9,1.6,3.7,2.2,5.3,2.2c0.8,0,1.6-0.2,2.2-0.4c1.5-0.7,2.4-2,2.4-3.6C67.9,42.5,67,41.2,65.5,40.5z M43.8,34.9
		c-1.7,1.4-3.3,1.4-4.2,1c-0.4-0.2-0.8-0.5-0.8-1.2c0-0.7,0.4-1,0.8-1.2c0.3-0.1,0.7-0.2,1.1-0.2c0.8,0,2,0.3,3.1,1.2
		C43.9,34.7,43.9,34.8,43.8,34.9L43.8,34.9z M64.5,45.3c-0.9,0.4-2.4,0.3-4.2-1c-0.1-0.1-0.1-0.2,0-0.3c1.7-1.4,3.3-1.4,4.2-1
		c0.4,0.2,0.8,0.5,0.8,1.2C65.3,44.8,64.8,45.1,64.5,45.3z"/>
          <path d="M37.1,14.1c0.3-0.2,0.9-0.6,1.9-0.7H67c0.1,0,0.2-0.1,0.2-0.2V11c0,0,0,0,0,0v-0.8c0-0.1-0.1-0.1-0.2-0.1
		c-0.3,0.2-1,0.7-2.1,0.7H37.1C37,10.8,37,10.9,37,11v2.3v0v0.8C37,14.1,37.1,14.2,37.1,14.1z"/>
        </g>
      </svg>
    </>
  );
}