// React
import { useMemo, useEffect } from 'react';
// 外部ライブラリ
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join, nl2br } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
// コンポーネント
import NewsList from '../../models/news/NewsList'
import PrimaryHeading from 'components/headings/PrimaryHeading'
// 型定義
// 定数
import { SITE_TITLE, SITE_URL_BASE } from 'config/Constants';
// 画像
// その他
type Props = {
}

export default function Index(props: Props) {

  // アニメーション用
  useEffect(() => {
    const observer = commonAnimationObserver();
    return () => {
      observer?.disconnect();
    };
  }, []);

  const index = useMemo(() => {
    return (
      <>
        <Helmet>
          <title>{`News｜${SITE_TITLE}`}</title>
          <meta property="og:url" content={SITE_URL_BASE + "/news"} />
          <meta property="og:title" content={`News｜${SITE_TITLE}`} />
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }} // このページに遷移した直後
          animate={{ opacity: 1 }} // 最終的な状態
          exit={{ opacity: 0 }} // 離れる際にどうなるか
          transition={{ duration: 0.5 }} // 切り替えに要する時間
        >
          <main id="p__news_index" className='l__main_content'>
            <PrimaryHeading>News</PrimaryHeading>
            <NewsList limit={10} pagenate={true} category={true} />
          </main>
        </motion.div>
      </>
    );
  }, [])

  return (
    <>
      {index}
    </>
  );

}