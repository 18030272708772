import { useState, useEffect, useContext } from "react";
import React from "react";
import DateItem from "./DateItem";
import dayjs from "dayjs";
import axios from 'axios';
import { DAY_NAMES, SITE_URL_BASE, API_BASE_URL } from 'config/Constants';
import { dayNameColor } from 'modules/projectModules'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { getParam } from "modules/url";
import { range } from "modules/calc";
import { off } from "process";

type Props = {
  // targetMonth: dayjs.Dayjs
  today: dayjs.Dayjs
  prevLimit: dayjs.Dayjs
  nextLimit: dayjs.Dayjs
  targetDate: dayjs.Dayjs
}

type Events = {
  year: {
    [key: string]: {
      month: {
        [key: string]: {
          dates: {
            [key: string]: [{}]
          }
        }
      }

    }
  }
}



function initEventsObj(startYear: number, endYear: number): Events {
  const yearRange: number[] = range(startYear, endYear);

  const eventsObj: Events = { year: {} };

  for (let i = 0; i <= yearRange.length; i++) {
    // const key = Object.keys(events[i])[0];
    eventsObj.year[String(yearRange[i])] = {
      month: {
        "1": {
          dates: {}
        },
        "2": {
          dates: {}
        },
        "3": {
          dates: {}
        },
        "4": {
          dates: {}
        },
        "5": {
          dates: {}
        },
        "6": {
          dates: {}
        },
        "7": {
          dates: {}
        },
        "8": {
          dates: {}
        },
        "9": {
          dates: {}
        },
        "10": {
          dates: {}
        },
        "11": {
          dates: {}
        },
        "12": {
          dates: {}
        },
      },
    };
  }

  // const events: YearInfo[] = yearRange.map((year): YearInfo => {
  //   return {
  //     [String(year)]: {
  //       month: {
  //         "1": {
  //           dates: {}
  //         },
  //         "2": {
  //           dates: {}
  //         },
  //         "3": {
  //           dates: {}
  //         },
  //         "4": {
  //           dates: {}
  //         },
  //         "5": {
  //           dates: {}
  //         },
  //         "6": {
  //           dates: {}
  //         },
  //         "7": {
  //           dates: {}
  //         },
  //         "8": {
  //           dates: {}
  //         },
  //         "9": {
  //           dates: {}
  //         },
  //         "10": {
  //           dates: {}
  //         },
  //         "11": {
  //           dates: {}
  //         },
  //         "12": {
  //           dates: {}
  //         },
  //       },
  //     },
  //   }
  // })

  // console.log("events", events)


  // const eventsKeys = Object.keys(events[0][String(startYear)].month["1"].dates)

  // for (let i = 0; i <= events.length; i++) {
  //   const key = Object.keys(events[i])[0];
  //   returnObj.year[key] = events[i][key];
  // }


  // console.log("returnObj", returnObj)

  return eventsObj;

}

// const today = dayjs()
// // const year = today.year(); // 現在の年を取得
// const prevLimit = today.add(-7, 'month') // 6ヶ月前まで遡れる(dayjsのmonthIndexが0始まりなので-7を設定)
// const nextLimit = today.add(6, 'month') // 6ヶ月後まで進められる

export default function MonthCalender(props: Props) {

  const today = props.today;
  const targetDate = props.targetDate;
  const prevLimit = props.prevLimit;
  const nextLimit = props.nextLimit;
  const prevYearLimit: string = prevLimit.format("YYYY") // 6ヶ月前まで遡れる(dayjsのmonthIndexが0始まりなので-7を設定)
  const nextYearLimit: string = nextLimit.format("YYYY") // 6ヶ月後まで進められる



  const [allEvents, setAllEvents] = useState<Events | null>(null);

  const [currentMonthCalender, setCurrentMonthCalender] = useState([[{}]]);

  useEffect(() => {
    if (allEvents) {
      setCurrentMonthCalender(generateMonthCalender());
    }
  }, [targetDate, allEvents]);

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `/schedule_list?prevLimit=${prevLimit.format("YYYY-MM-DD")}&nextLimit=${nextLimit.format("YYYY-MM-DD")}${previewModeQuery}`
    const uri = API_BASE_URL + query
    return uri
  }

  function generateAllEventArray(contents: any) {

    const events: Events = initEventsObj(Number(prevYearLimit), Number(nextYearLimit));

    for (const event of contents) {

      // 2022/08/02 のような文字列から0を削除し、年と月と日に分ける 
      const monthDate = event.date.split('/');
      const year: keyof Object = monthDate[0].replace(/^0+/, '');
      const month: keyof Object = monthDate[1].replace(/^0+/, '');
      const date: keyof Object = monthDate[2].replace(/^0+/, '');

      // events.month[month].dates[date]が空だった場合、何か値を入れて配列として扱わないとpushが使えないため、
      // 空の場合とそうでない場合で入れ方を変える
      if (events.year[year].month[month].dates[date] === undefined) {
        events.year[year].month[month].dates[date] = [{
          title: event.title,
          category: event.cat_names[0],
          description: event.description,
        }];
      } else {
        events.year[year].month[month].dates[date].push({
          title: event.title,
          category: event.cat_names[0],
          description: event.description,
        });
      }
    };
    return events;
  }

  // スケジュールを取得
  useEffect(() => {
    axios({
      method: 'get',
      url: composeApiUrl(),
      timeout: 10000
    })
      .then(function (res) {
        // 記事情報のセット
        setAllEvents(generateAllEventArray(res.data.contents));
        // console.log("generateAllEventArray(res.data.contents)", generateAllEventArray(res.data.contents))
      })
      .catch(function (e) {
        setAllEvents(generateAllEventArray([]));
      })
      .finally(function () {
        loaded(".month_calender_wrapper");
      })
  }, []);

  // 指定された月のカレンダーの二次元配列を取得
  function generateMonthCalender() {

    const year: string = targetDate.format("YYYY");
    const month: string = targetDate.format("M");

    // 指定された月の1日の日付を取得
    const firstDateOfTargetMonth = dayjs(`${year}/${month}/1`);

    // 指定された月(表示中の月)の1日の曜日を計算
    const firstDayOfTargetMonth = firstDateOfTargetMonth.day();

    // ループ中で対象とする曜日を初期化
    // 指定された月の1日の曜日が週の途中だった場合、前月の日付も含める必要があるため、0から引く
    let cursorDay = 0 - firstDayOfTargetMonth;

    // カレンダーの日付を格納するための2次元配列を作成
    // 7日間 * 6週分
    const daysMatrix = new Array(6).fill([]).map(() => {
      return new Array(7).fill(null).map((v, index) => {

        // 配列に追加する日付の数字を計算
        const targetDate = firstDateOfTargetMonth.add(cursorDay, "day");
        const targetDateFormatted = targetDate.format("D");

        //曜日を進める
        cursorDay++

        const event = allEvents?.year[year].month[month]?.dates[targetDateFormatted]

        // 日付の中身のオブジェクト
        return ({
          date: targetDate,  // 日付
          dayName: DAY_NAMES[index % 7],  // 曜日
          event: event,
          today: targetDate.format("YY-MM-DD") === today.format("YY-MM-DD"),  // 今日の日付かどうか
          currentMonth: targetDate.format("YY-MM") === firstDateOfTargetMonth.format("YY-MM"),  // 表示中の月の日付かどうか
        })
      });
    });

    // 2次元の日付マトリックスを返す
    return daysMatrix;
  }

  return (
    <>
      <div className="month_calender_wrapper js__load_required">

        <div className="day_names">
          {DAY_NAMES.map((dayName, i) => (
            <div className={`name ${dayNameColor(dayName)}`} key={i}>{dayName}</div>
          ))}
        </div>

        <div className="month_calender">
          {currentMonthCalender.map((row, i) => (
            <React.Fragment key={i}>
              {row.map((date, idx) => (
                <DateItem dateInfo={date} key={idx} />
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};