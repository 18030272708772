// React
import { useEffect, useState } from 'react'
// 外部ライブラリ
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import axios from 'axios';
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { nl2br } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
import { getNewsCategoryColorClass, historyCategoryColor, nl2li } from 'modules/projectModules'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
// コンポーネント
import PrimaryHeading from 'components/headings/PrimaryHeading'
import ActiveCategoryLine from 'components/ui/commonSvg/ActiveCategoryLine'
// 型定義
// 定数
import { SITE_TITLE, SITE_URL_BASE, API_BASE_URL } from 'config/Constants';
// 画像
// 型定義
import { History } from "./Types";
// その他


type Props = {
}
const initialArticle: History = {
  anime: "",
  game: "",
  live: "",
  others: "",
};

export default function Index(props: Props) {

  const [allHistory, setAllHistory] = useState<History>(initialArticle);
  const [currentHistory, setCurrentHistory] = useState("");
  const [catName, setCatName] = useState<keyof History>("anime");

  /*=======================================
    背景操作用
  =======================================*/

  // アニメーション用
  useEffect(() => {
    const observer = commonAnimationObserver();
    return () => {
      observer?.disconnect();
    };
  }, []);

  function composeApiUrl(): string {
    const query = `/history`
    const uri = API_BASE_URL + query
    return uri
  }

  // 記事詳細を取得
  useEffect(() => {

    axios({
      method: 'get',
      url: composeApiUrl(),
      timeout: 10000
    })
      .then(function (res) {

        // 記事情報のセット
        const artice: History = {
          "anime": res.data.anime,
          "game": res.data.game,
          "live": res.data.live,
          "others": res.data.others,
        };
        // 記事情報のセット
        setAllHistory(artice);
        setCurrentHistory(artice[catName]);
      })
      .catch(function (e) {
      })
  }, []);

  function handleClick(name: keyof History) {
    const historyWrapper = qs(".history_wrapper");
    addClass(historyWrapper, "switch");
    setCatName(name);
    setTimeout(() => {
      setCurrentHistory(allHistory[name]);
      removeClass(historyWrapper, "switch");
    }, 500);
  }

  return (
    <>
      <ul className="history_categories_list">
        <li className={"category_item " + historyCategoryColor("Anime") + (catName === "anime" ? " active" : "")}>
          <button className={"category_buttons"} onClick={() => handleClick("anime")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>Anime</button>
          <ActiveCategoryLine />
        </li>
        <li className={"category_item " + historyCategoryColor("Game") + (catName === "game" ? " active" : "")}>
          <button className={"category_buttons"} onClick={() => handleClick("game")} onMouseOver={activateHoverCursor}
            onMouseOut={deactivateHoverCursor}>Game</button>
          <ActiveCategoryLine />
        </li>
        <li className={"category_item " + historyCategoryColor("Live") + (catName === "live" ? " active" : "")}>
          <button className={"category_buttons"} onClick={() => handleClick("live")} onMouseOver={activateHoverCursor}
            onMouseOut={deactivateHoverCursor}>Live</button>
          <ActiveCategoryLine />
        </li>
        <li className={"category_item " + historyCategoryColor("Others") + (catName === "others" ? " active" : "")}>
          <button className={"category_buttons"} onClick={() => handleClick("others")} onMouseOver={activateHoverCursor}
            onMouseOut={deactivateHoverCursor}>Others</button>
          <ActiveCategoryLine />
        </li>
      </ul>
      <div className="history_wrapper" dangerouslySetInnerHTML={{ __html: currentHistory }} />
    </>
  );
}