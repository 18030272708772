import { useEffect } from 'react';
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { isMobile } from 'react-device-detect';

// React
// 外部ライブラリ
// 内部ライブラリ
// コンポーネント
// 型定義
// 定数
// 画像
// その他
const MouseStalker = () => {

  let stalker: any;
  useEffect(() => {
    if (!isMobile) {
      stalker = qs('#mouse_stalker');
      document.addEventListener('mousemove', stalk);
      setTimeout(() => {
        removeClass(stalker, "disabled");
      }, 3000);
    }
    return () => {
      document.removeEventListener('mousemove', stalk);
    }
  }, []);

  function stalk(e: any) {
    stalker.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
  }

  return (
    <>

    </>
  );
}
export default MouseStalker;