import { useCallback } from 'react'
import { useSetRecoilState, useRecoilValue } from "recoil"
import { scheduleCategoryAtom } from "../atom/GlobalAtom"
import { ScheduleCategory } from 'components/models/scheduleCategory/Types';
// 背景のstateを管理
type Background = State & {
  // setJson: () => void;
  // deactivate: () => void;
  setScheduleCategory: (arg: ScheduleCategory[]) => void
};

type State = {
  scheduleCategory: ScheduleCategory[];
};

export default function useScheduleCategoryColor(): Background {

  const scheduleCategory = useRecoilValue(scheduleCategoryAtom);
  const setState = useSetRecoilState(scheduleCategoryAtom);

  const setScheduleCategory = useCallback((arg: ScheduleCategory[]) => {
    setState(arg);
  }, []);

  // const deactivate = useCallback(() => setIsActive(false), []);

  return {
    scheduleCategory,
    setScheduleCategory, // Add the missing setJson property
  };
}