
import './assets/scss/application.scss';
import { setDvh, setSvh } from 'modules/screen'
import { Helmet } from "react-helmet-async"
import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import Router from 'config/Router';
import Header from 'components/layouts/header/Header';
import Footer from 'components/layouts/footer/Footer';
import MouseStalker from 'components/ui/MouseStalker';
import { SITE_TITLE, SITE_DESCRIPTION_BASE, SITE_URL_BASE, API_BASE_URL } from 'config/Constants';
import { defineCategoryNumber } from 'modules/projectModules';
import axios from 'axios';
import useNewsCategoryColor from 'hooks/useNewsCategoryColor';
import useScheduleCategoryColor from 'hooks/useScheduleCategoryColor';

export default function App() {

  useEffect(() => {
    setDvh();
    setSvh();
    window.addEventListener('resize', setDvh);
    return () => {
      window.removeEventListener('resize', setDvh);
    }
  }, []);


  //-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  // 記事カテゴリを取得しカテゴリカラーを設定
  //-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  const newsCategoryColor = useNewsCategoryColor();
  function composeApiUrl(categoryTaxonomy: string): string {
    const query = `/${categoryTaxonomy}`;
    const uri = API_BASE_URL + query
    return uri
  }
  useEffect(() => {
    axios({
      method: 'get',
      url: composeApiUrl("article_category"),
      timeout: 5000
    })
      .then(function (res) {
        // console.log(res.data)

        const categoryColorJson = defineCategoryNumber(res.data, 1); // Allを0にしたいので1オリジン
        newsCategoryColor.setNewsCategory(categoryColorJson); // Modify this line
        // console.log("categoryColorJson", categoryColorJson)
      })
      .catch(function (error) {
      })
      .finally(function () {
        // loaded(".c__categories_list");
      });
  }, [])


  //-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  // スケジュールカテゴリを取得しカテゴリカラーを設定
  //-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  const scheduleCategoryColor = useScheduleCategoryColor();
  useEffect(() => {
    axios({
      method: 'get',
      url: composeApiUrl("schedule_category"),
      timeout: 5000
    })
      .then(function (res) {
        // console.log(res.data)

        const categoryColorJson = defineCategoryNumber(res.data, 0); // 普通に始めから割り振りたいので0オリジン
        scheduleCategoryColor.setScheduleCategory(categoryColorJson); // Modify this line
        // console.log("categoryColorJson", categoryColorJson)
      })
      .catch(function (error) {
      })
      .finally(function () {
        // loaded(".c__categories_list");
      });
  }, [])

  return (
    <>
      <Helmet>
        <title>{SITE_TITLE}</title>
        <meta name="description" content={SITE_DESCRIPTION_BASE} />
        <meta property="og:image" content={SITE_URL_BASE + "/ogp.jpg"} />
        <meta property="og:url" content={SITE_URL_BASE} />
        <meta property="og:title" content={SITE_TITLE} />
        <meta property="og:description" content={SITE_DESCRIPTION_BASE}></meta>
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <BrowserRouter>
        <div id="yeild" >
          <Header />
          <Router />
          <Footer />
        </div>
      </BrowserRouter>
      <MouseStalker />
    </>
  );
}